/* src/App.css */
.App {
  height: 100vh;
  margin: 0;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 10px;
}

.field {
  max-width: 100px;
  overflow: hidden;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.desc-field {
  max-width: 150px;  
  /* display: flex;
  align-items: center;
  justify-content: center; */
}